<template>
    <div class="common-table search-result">
        <div class="common-table__title">按省份统计分析
        </div>
        <div v-if="isEmpty" class="search-result__container" style="margin-top: 20px;padding-top: 100px;">
            <ts-empty></ts-empty>
        </div>
        <div v-else class="search-result-body">
            <el-tabs v-model="tab" type="border-card">
                <el-tab-pane name="year"><span slot="label">{{ pageMap.year }}</span></el-tab-pane>
                <el-tab-pane name="top"><span slot="label">{{ pageMap.top }}</span></el-tab-pane>
                <el-tab-pane name="amount"><span slot="label">{{ pageMap.amount }}</span></el-tab-pane>
            </el-tabs>
        </div>

        <div class="search-result__container" v-if="loaded && !isEmpty">
            <div class="search-result__panel" :class="{show: tab === 'year'}">
                <province-year v-if="finished.year" :visit-id="visitId"></province-year>
            </div>
            <div class="search-result__panel" :class="{show: tab === 'top'}">
                <province-top v-if="finished.top" :visit-id="visitId"></province-top>
            </div>
            <div class="search-result__panel" :class="{show: tab === 'amount'}">
                <province-portion-data v-if="finished.amount" :visit-id="visitId">
                </province-portion-data>
            </div>
        </div>
    </div>
</template>

<script>
    // import ProvinceAmount from './ProvinceAmount'
    import ProvincePortionData from './ProvincePortionData'
    import ProvinceTop from './ProvinceTop'
    import ProvinceYear from './ProvinceYear'
    export default {
        components: {ProvincePortionData, ProvinceTop, ProvinceYear},
        data() {
            return {
                visitId: 0,
                pageId: '',
                pageName: '',
                pageMap: {
                    year: '各省历年药品销售数据',
                    top: '历年各省份药品销售TOP30',
                    amount: '各省份历年药品销售份额数据',
                },
                tab: 'year',
                loaded: true,
                finished: {
                    year: false,
                    top: false,
                    amount: false,
                },
                isEmpty: false,
            }
        },
        created() {
            this.init()
        },
        watch: {
            '$route': function () {
                this.init()
            },
            tab: {
                handler(val) {
                    this.finished[val] = true

                    this.visitId = new Date().getTime()
                    this.pageId = val
                    this.pageName = '省份分析-' + this.pageMap[val]

                    this.$help.socket.send(this)
                },
                immediate: true
            },
        },
        methods: {
            init() {
                this.isEmpty = false
            },
        },
    }
</script>